<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <v-data-table
          :search="search"
          loading-text="Loading... Please wait"
          :headers="headers"
          :items="region.salesReps"
          item-key="id"
        >
          <template v-slot:item.AssignedToRegion="{ item }">
            <v-switch
              :loading="switchLoader"
              v-model="item.AssignedToRegion"
              :true-value="trueValue"
              :false-value="falseValue"
              @change="employeeAction(item)"
            ></v-switch>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("salesEmployee", ["salesEmployees"]),
  },
  data() {
    return {
      search: null,
      loading: true,
      trueValue: 1,
      falseValue: 0,
      region: {},
      switchLoader: false,
      headers: [
        { text: "Name", value: "SlpName" },
        { text: "Action", value: "AssignedToRegion" },
      ],
    };
  },
  watch: {
    "$route.params.id": {
      handler: "getData",
      immediate: true,
    },
  },
  methods: {
    employeeAction(data) {
      const employee = data;
      const employeeId = employee.id;
      const regionId = this.region.id;
      const url = `/territory/${regionId}/${employeeId}`;
      if (employee.AssignedToRegion === 1) {
        const url = `/territory/${regionId}/${employeeId}`;
        this.$store
          .dispatch("post", { url, data })
          .then(() => {
            location.reload();
          })
          .catch((err) => {
            console.log(err, "err");
            // this.$refs.snackbar.show(err, "red");
          });
      } else {
        this.$store
          .dispatch("remove",  url)
          .then(() => {
            location.reload();
          })
          .catch((err) => {
            console.log(err, "err");
            // this.$refs.snackbar.show(err, "red");
          });
      }
    },
    getData(val) {
      if (val) {
        const self = this;
        this.loading = true;
        this.$store
          .dispatch("get", `/territory/${val}`)
          .then((res) => {
            self.region = res;
            self.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
</style>